<template>
  <div style="background-color: #f5f5f5;margin-top:10px;padding-bottom: 10px;">
    <el-row style="  background: #fff;">
      <el-col :span="18" :offset="3">
        <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span @click="changeNav(1)">磐石云终端</span> &gt; <span>{{navList[isAction].title}}</span></p>
      </el-col>
    </el-row>
<!--    顶部终端列表-->
    <el-row class="navListBox">
      <ul class="navList">
        <li v-for="(item,index) in navList" :key="index" :class="{action:index == isAction}" @click="showNav(index)">
          <img :src="item.imgSrc">
          <p style="margin: 23px 0;font-size: 22px;">{{item.title}}</p>
        </li>
      </ul>
    </el-row>

<!--    顶部-->
    <el-row>
      <el-row class="frameworkList">
        <el-col :span="14" style="padding-left: 10%">
          <p style="font-size: 36px;text-align: left; font-weight: bold;color: #101010">{{navList[isAction].pro_title}}</p>
          <p style="font-size: 39px;text-align: left;margin: 5% 0">{{navList[isAction].pro_tit}}</p>
          <p style="font-size: 16px;text-indent: 2em; color: #888;line-height: 28px;width: 80%;text-align: justify;">{{navList[isAction].pro_det}}</p>
        </el-col>
        <el-col :span="10" class="frameworkListImg">
          <img :src="navList[isAction].imgJg"  >
        </el-col>
      </el-row>
    </el-row>

<!--    外观接口-->
    <el-row>
      <el-row class="frameworkList">
        <el-row style="font-size: 36px;color: #101010;margin-bottom: 2%">外观接口图例</el-row>
        <el-row>
          <img :src="navList[isAction].Appearance_interface" width="100%"  >
        </el-row>
      </el-row>
    </el-row>
    
<!--    配置一览-->
    <el-row style="margin: 0 12.5% 3% 12.5%;" class="product_configure">
      <p class="pzyl">产品配置一览</p>
      <img :src="navList[isAction].configure">
    </el-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getProductsShowList} from '@/api'
export default {
  name: "cloudTerminal",
  data(){
    return{
      // isAction:0,
      navListTemp:[],
      // 磐石云终端列表
      navList:[
          // {
          //   id:1,
          //   imgSrc:require('@/assets/panshProduct/production/2711.png'),
          //   imgJg:require('@/assets/panshProduct/production/2711jg.jpg'),
          //   title:'PS 2711+',
          //   pro_title:'PS 2711+',
          //   pro_tit:'精益所致  磐石为开',
          //   pro_det:'精益所致、磐石为开，磐石系列云终端解决方案支持构建行业特色应用场景，采用全面固化基础架构设计，配置独立电源管理模块，提供丰富弹性扩展空间，部署深度优化操作系统，支持终端管理，支持可靠持久运行。磐石云终端，致力行业产能提升！',
          //   pro_Appearance:require('@/assets/panshProduct/production/2711.png'),
          //   Appearance_interface:require('@/assets/panshProduct/production/2711_1.png'),
          //   configure:require('@/assets/panshProduct/production/2711_2.jpg')
          // },
          // {
          //   id:2,
          //   imgSrc:require('@/assets/panshProduct/production/8350.png'),
          //   imgJg:require('@/assets/panshProduct/production/8350jg.jpg'),
          //   title:'PS 8350',
          //   pro_title:'PS 8350',
          //   pro_tit:'还淳返朴  磐石之安',
          //   pro_det:'还淳返朴，磐石之安，磐石系列云终端产品以其经典造型、多能配置、丰富扩展、全面固化、可靠保障等功能特性支持适用各应用场景，既可部署于工作组级应用，又能满足高覆盖企业级应用。',
          //   pro_Appearance:require('@/assets/panshProduct/production/8350.png'),
          //   Appearance_interface:require('@/assets/panshProduct/production/8350_1.png'),
          //   configure:require('@/assets/panshProduct/production/8350_2.png')
          // },
          // {
          //   id:3,
          //   imgSrc:require('@/assets/panshProduct/production/1900.png'),
          //   imgJg:require('@/assets/panshProduct/production/1900jg.jpg'),
          //   title:'PS 1900/1900+',
          //   pro_title:'PS 1900/1900+',
          //   pro_tit:'沉稳凝邃 安如磐石',
          //   pro_det:'沉稳凝邃 安如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
          //   pro_Appearance:require('@/assets/panshProduct/production/1900.png'),
          //   Appearance_interface:require('@/assets/panshProduct/production/1900_1.png'),
          //   configure:require('@/assets/panshProduct/production/1900_2.png')
          // },
          {
            id:4,
            imgSrc:require('@/assets/panshProduct/production/4405.png'),
            imgJg:require('@/assets/panshProduct/production/4405jg.jpg'),
            title:'PS 4405/4405+',
            pro_title:'PS 4405/4405+',
            pro_tit:'精致雕琢  坚如磐石',
            pro_det:'精致雕琢，坚如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/4405.png'),
            Appearance_interface:require('@/assets/panshProduct/production/4405_1.png'),
            configure:require('@/assets/panshProduct/production/4405_2.png')
          },
          {
            id:5,
            imgSrc:require('@/assets/panshProduct/production/7500.png'),
            imgJg:require('@/assets/panshProduct/production/7500jg.jpg'),
            title:'PS 7500',
            pro_title:'PS 7500',
            pro_tit:'幻彩逸生  泰山磐石',
            pro_det:'幻彩逸生  泰山磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/7500.png'),
            Appearance_interface:require('@/assets/panshProduct/production/7500_1.png'),
            configure:require('@/assets/panshProduct/production/7500_2.png')
          },
          {
            id:6,
            imgSrc:require('@/assets/panshProduct/production/4405.png'),
            imgJg:require('@/assets/panshProduct/production/6540jg.jpg'),
            title:'PS Z6540',
            pro_title:'PS Z6540',
            pro_tit:'坚韧紧致  助力信创',
            pro_det:'坚韧紧致  助力信创，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/4405.png'),
            Appearance_interface:require('@/assets/panshProduct/production/4405_1.png'),
            configure:require('@/assets/panshProduct/production/6540_3.jpg')
          },
      ]
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('cloudTerminalMobile')
    }
    // this.getShowList()
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 切换导航
    showNav(index){
      this.$store.dispatch('changeActive',index)
    },
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
    // 获取产品列表
    async getShowList(){
      const result = await getProductsShowList()
      // 判断需要展示的云终端进行展示
      result.data.filter((item,index) => {
        if(item.flag == 0){
          this.navList.push(this.navListTemp[index])
        }
      })
    }
  },
  computed:{
    // 获取仓库中的数据
    ...mapState({
      isAction:state => state.home.active,
    })
  },
  mounted() {
    // 切换云终端激活样式
    this.$nextTick(() => {
      this.$store.dispatch('changeActive',this.$route.query.active)
    })
  }
}
</script>

<style scoped>
/* 通用样式 */
li{
  list-style: none;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;

}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/* 配置一览 */
.pzyl{
  font-size: 36px;
  margin-bottom: 2%;
  padding-left: 2%;
}
/* 6540 */
.img6540{
  width: 100%;
}
/* 页面样式 */
.frameworkList{
  background-color: #fff;
  margin: 0 12.5% 3% 12.5%;
  padding: 44px 2% 48px 2%;
}
/*顶部导航栏*/
.navListBox{
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 3%;
}
.navList{
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 0 3%;
  padding: 2% 0 0;
}
.navList li{
  cursor: pointer;
}
.navList li img{
  height: 110px;
}
.navList li.action{
  border-bottom: 3px solid #000000;
}
.product_configure img{
  max-width: 100%;
  margin: 0 auto;
}
.frameworkListImg img{max-width: 100%;}
@media screen and (min-width: 1350px){
  .navList{margin: 0 10%;}
}
</style>